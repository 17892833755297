import React from 'react';

const AdminForm = ({id: key, majRecette, supprimerRecette, recettes}) => {
    const recette = recettes[key]

    const handleChange = (event, key) => {
        const {name, value} = event.target
        const recette = recettes[key]
        recette[name] = value
        majRecette(key, recette)
    }
    return (
        <div className="card">
           <form className="admin-form">
                <input type="text" name="nom" value={recette.nom} onChange={e => handleChange(e, key)} placeholder="Nom de la recette" />
                <input type="text" name="image" value={recette.image} onChange={e => handleChange(e, key)} placeholder="Nom de l'image" />
                <textarea name="ingredients" value={recette.ingredients} onChange={e => handleChange(e, key)} rows="3" placeholder="Liste des ingrédients"></textarea>
                <textarea name="instructions" value={recette.instructions} onChange={e => handleChange(e, key)} rows="15" placeholder="Liste des instructions"></textarea>
            </form> 
            <button onClick={() => supprimerRecette(key)}>Supprimer</button>
        </div>
    );
};

export default AdminForm;